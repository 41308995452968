import React from "react"
import SEO from "../../components/seo"
import { kebabCase } from "lodash"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import Container from "../../components/Container"
import Row from "../../components/Row"
import Layout from "../../components/Layout"

class AuthorsPage extends React.Component {
  render() {
    const { data } = this.props
    return (
      <>
        <SEO title="Authors" />
        <Helmet title={`Authors | ${data.site.siteMetadata.title}`}>
          {/* Font Awesome */}
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
            integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
            crossorigin="anonymous"
          />
          {/* Materialize Icons */}
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
            rel="stylesheet"
          />
          {/* Google Fonts */}
          <link
            href="https://fonts.googleapis.com/css?family=Oswald&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Layout>
          <Container>
            <Row>
              <div className="col s12">
                <h1 className="blog-header">All Authors</h1>
              </div>
            </Row>
            <Row>
              <div className="col s12">
                <ul className="taglist">
                  {data.allMarkdownRemark.group.map(author => (
                    <li key={author.fieldValue} className="all-tag-list">
                      <Link to={`/authors/${kebabCase(author.fieldValue)}/`}>
                        {author.fieldValue} ({author.totalCount})
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Row>
          </Container>
        </Layout>
      </>
    )
  }
}

export default AuthorsPage

export const authorPageQuery = graphql`
  query AuthorsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___author) {
        fieldValue
        totalCount
      }
    }
  }
`
